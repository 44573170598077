import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const newsroomTranslations = {
    en: {
        metaTitle: "Talend in the News: Get the Latest on Talend and Data",
        metaDescription:
            "Talend is the leader in data integration, data integrity, and data health. Find out how we shorten the time to trusted data.",
        pressKit: "Press kit",
        pressKitDownload: "Download the press kit",
        mediaInquiries: "Media inquiries",
        contact: "Contact:",
        pressReleasesTitle: "Press releases",
        newsArticlesTitle: "In the news",
        viewMoreCopy: {
            pressReleases: common.en.viewMore,
            newsArticles: "Read all news",
        },
        breadcrumbs: {
            newsroom: "Newsroom",
            pressReleases: "Press releases",
        },
    },
    "en-GB": {
        metaTitle: "Talend in the News: Get the Latest on Talend and Data",
        metaDescription:
            "Talend is the leader in data integration, data integrity, and data health. Find out how we shorten the time to trusted data.",
        pressKit: "Press kit",
        pressKitDownload: "Download the press kit",
        mediaInquiries: "Media inquiries",
        contact: "Contact:",
        pressReleasesTitle: "Press releases",
        newsArticlesTitle: "In the news",
        viewMoreCopy: {
            pressReleases: common["en-GB"].viewMore,
            newsArticles: "Read all news",
        },
        breadcrumbs: {
            newsroom: "Newsroom",
            pressReleases: "Press releases",
        },
    },
    fr: {
        metaTitle: "Talend dans les médias : ne manquez pas les dernières infos sur Talend et les données",
        metaDescription:
            "Talend est le leader mondial de l’intégration, de l'intégrité et de la santé des données. Découvrez comment nous accélérons la mise à disposition de données fiables.",
        pressKit: "Dossier de presse",
        pressKitDownload: "Télécharger le dossier de presse",
        mediaInquiries: "Contact médias",
        contact: "Contact :",
        pressReleasesTitle: "Communiqués de presse",
        newsArticlesTitle: "Dans les médias",
        viewMoreCopy: {
            pressReleases: common.fr.viewMore,
            newsArticles: "Lire tous les articles",
        },
        breadcrumbs: {
            newsroom: "Presse",
            pressReleases: "Communiqués de presse",
        },
    },
    de: {
        metaTitle: "Talend in den Medien: Aktuelle Informationen zu Talend und Daten",
        metaDescription:
            "Talend gehört zu den Marktführern in den Bereichen Datenintegration, Datenintegrität und Data Health. Erfahren Sie, wie Sie mit uns schneller an belastbare Daten kommen.",
        pressKit: "Pressekit",
        pressKitDownload: "Pressekit herunterladen",
        mediaInquiries: "Presseanfragen",
        contact: "Ihr Ansprechpartner:",
        pressReleasesTitle: "Pressemitteilungen",
        newsArticlesTitle: "In den Medien",
        viewMoreCopy: {
            pressReleases: common.de.viewMore,
            newsArticles: "Alle Neuigkeiten lesen",
        },
        breadcrumbs: {
            newsroom: "Newsroom",
            pressReleases: "Pressemitteilungen",
        },
    },
    it: {
        metaTitle: "Notizie su Talend: tutte le ultime novità su Talend e i dati",
        metaDescription:
            "Talend è leader nelle tecnologie per l'integrazione, l'integrità e la salute dei dati. Scopri come accorciamo i tempi per produrre dati affidabili.",
        pressKit: "Press kit",
        pressKitDownload: "Scarica il press kit",
        mediaInquiries: "Informazioni per i media",
        contact: "Referente:",
        pressReleasesTitle: "Comunicati stampa",
        newsArticlesTitle: "Rassegna stampa",
        viewMoreCopy: {
            pressReleases: common.it.viewMore,
            newsArticles: "Leggi tutte le notizie",
        },
        breadcrumbs: {
            newsroom: "Ultime notizie",
            pressReleases: "Comunicati stampa",
        },
    },
    ja: {
        metaTitle: "Talendニュース：Talendとデータに関する最新情報をご覧ください",
        metaDescription:
            "Talendは、データ統合、データ整合性、およびデータの健全性のリーダーです。信頼できるデータの取得までにかかる時間をTalendがどのように短縮しているのかをご覧ください。",
        pressKit: "プレスキット",
        pressKitDownload: "プレスキットのダウンロード",
        mediaInquiries: "メディア関連のお問い合わせ",
        contact: "連絡先",
        pressReleasesTitle: "プレスリリース",
        newsArticlesTitle: "話題のニュース",
        viewMoreCopy: {
            pressReleases: "さらに表示",
            newsArticles: "すべてのニュースを読む",
        },
        breadcrumbs: {
            newsroom: "ニュースルーム",
            pressReleases: "プレスリリース",
        },
    },
};
