import React from "react";
import SingleNewsEntryItem from "../../singleNewsEntryItem/SingleNewsEntryItem";
import { entryMap } from "../../../utils/entryMap";
import { useI18n } from "@v4/utils/i18nContext";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import CTA from "@v4/talend/src/components/cta/Cta";
import { newsroomTranslations } from "../../../translations/newsroom";

import classNames from "classnames/bind";
import * as newsEntryListingStyles from "./newsEntryListing.module.css";

// This component is used in the "Content Listing" component, as well as the Articles and Press-Release listing pages.
// There's a prop for "presentation" which handles the visual output of nodes as a list, or a grid of cards.

const NewsEntryListing = (props) => {
    // CX binding method for styles
    const cx = classNames.bind(newsEntryListingStyles);

    // Map the contentful field value (passed as the listType prop) to a content type
    const typeMap = {
        "Press Release Cards": "pressReleases",
        "News Articles": "newsArticles",
    };

    const data = props.data;

    // Establish which node type is being output, based on a prop
    const nodeType = typeMap[props.listType];

    // Establish the content data
    const contentData = data[nodeType].nodes;

    // Determine what sort of visual layout the content will take
    const presentation = nodeType === "pressReleases" ? "Card" : "List";

    // Determine limit per presentation type
    const limit = presentation === "Card" ? 6 : 3;

    // Add helper class
    const presentationClass = `newsEntryListing${presentation}`;

    // Set up i18n context
    const { i18n } = useI18n();

    // Translated strings
    const viewMoreCopy = newsroomTranslations[i18n.curr.langCode].viewMoreCopy[nodeType];

    return (
        <>
            {props.title && <div className={cx("newsEntryListingTitle")}>{props.title}</div>}
            {contentData && (
                <div className={cx(presentationClass)}>
                    {contentData.map(
                        (entry, index) =>
                            index < limit && (
                                <SingleNewsEntryItem
                                    key={`news-item--${index}`}
                                    {...entry}
                                    presentation={presentation}
                                />
                            )
                    )}
                </div>
            )}

            <div className={cx("loadMoreWrapper")}>
                <CTA
                    url={getLangPrefixedPath(entryMap[nodeType].pagePath, i18n.curr.langCode)}
                    variant="Tertiary"
                    title={viewMoreCopy}
                    customClass={cx("loadMore")}
                />
            </div>
        </>
    );
};

export default NewsEntryListing;
